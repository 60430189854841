<template>
  <div
    class="radio__container"
    :class="{
      'radio--error': errorMessage,
      'no-print': noPrint,
    }"
    v-if="visible"
  >
    <h4 class="radio__title" :class="{ 'radio__title--disabled': disabled }">
      <span class="required__symbol" v-if="required && !disabled">*</span>
      <span>{{ title }}</span>
    </h4>
    <div class="radio__container">
      <div class="print-list" v-for="(o, i) in options" :key="i">
        <p v-if="o.id === inputValue">{{ o.title }}</p>
      </div>
      <div class="radio__list no-print">
        <label
          :class="{
            'radio--disabled': disabled,
            'radio--checked': inputValue === o.id,
          }"
          class="radio"
          v-for="(o, i) in options"
          :key="i"
        >
          <span v-if="!hideState" class="radio__state">
            <CheckIcon />
          </span>
          <input
            type="radio"
            v-model="inputValue"
            :value="o.id"
            class="radio__input"
            :disabled="disabled"
          />
          <span class="radio__input-title">{{ o.name }}</span>
        </label>
      </div>
      <span class="form__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import petrovich from "petrovich"
import CheckIcon from "@/components/svg/CheckIcon";

export default {
  name: "RadioComponent",
  components: { CheckIcon },
  props: {
    noPrint: Boolean,
    title: String,
    placeholder: {
      type: String,
      default: "",
    },
    options: Array,
    fio: Object,
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    value: [Number, String, Object, Boolean],
    errorMessage: String,
    hideState: Boolean,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  mounted() {
    setTimeout(() => {
      this.inputValue = this.value;
    }, 0)
  },
  watch: {
    inputValue(e) {
      this.$emit("input", e);
    },
    "fio.first"() {
      this.defineGender()
    },
    "fio.middle"() {
      this.defineGender()
    },
    "fio.last"() {
      this.defineGender()
    }
  },
  methods: {
    defineGender() {
      let gender = petrovich(this.fio, 'nominative').gender
      if (gender === "male") {
        this.inputValue = this.options.find(item => item.id === 1).id
      } else if (gender === "female") {
        this.inputValue = this.options.find(item => item.id === 2).id
      }
    }
  }
};
</script>

<style lang="stylus">
.radio {
  display flex
  position relative
  cursor pointer
  align-items: center;
  text-align: left;
  user-select none
  font-size: 1em;
  line-height: 16px;
  transition 0.2s
  padding 3px
  margin 0

  > *:not(:last-child) {
    margin-right 15px
  }

  &:hover {
    color var(--main_color)

    .radio__state {
      border-color var(--main_color)
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom 10px
  }

  &__list {
    display flex
    align-items center

    > *:not(:last-child) {
      margin-right 12px
    }
  }

  input {
    absolute left top
    visibility hidden
  }

  .icon {
    display flex
  }

  &--error {
    color var(--color_red) !important

    .radio__state {
      border-color var(--color_red) !important
    }
  }

  &--disabled {
    color var(--color_gray)

    .radio__state {
      border: 1px solid var(--color_gray);
    }

    &.radio--checked {
      .radio__state {
        &:after {
          background: var(--color_dark);
        }
      }
    }
  }

  &--checked {
    &:not(&.radio--disabled) {
      & .radio__state {
        border none
        background var(--main_color)
        box-shadow: 0 4px 10px rgba(255, 145, 91, 0.5);
      }
    }
  }

  &__state {
    position relative
    width 22px
    height 22px
    background: var(--color_white);
    border: 1px solid var(--main_border_color);
    box-sizing: border-box;
    border-radius: 50%;
    display flex
    align-items center
    justify-content center
    flex-shrink 0
    margin-top 2px
    transition 0.2s
  }
}
</style>