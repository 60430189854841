var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('div',{staticClass:"check__container",class:{
    'check--error': _vm.errorMessage,
    'no-print': _vm.noPrint,
    'no-margin': _vm.noMargin,
  }},[_c('div',{staticClass:"check__list no-print"},[_c('label',{staticClass:"check",class:{
        'check--disabled': _vm.disabled,
        'check--checked': _vm.inputValue,
      }},[_c('span',{staticClass:"check__state",class:{ 'check__state--circle': _vm.circle }},[(_vm.circle)?_c('CheckIcon'):_vm._e()],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"check__input",attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"change":[function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}},_vm.handleChange]}}),_vm._t("default",function(){return [_c('span',{staticClass:"check__input-title"},[(_vm.required)?_c('span',{staticClass:"required__symbol"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")])]})],2)]),(_vm.errorMessage)?_c('span',{staticClass:"form__error",attrs:{"role":"alert"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(_vm.message)?_c('span',{staticClass:"form__item-message",attrs:{"role":"alert"}},[_vm._v(_vm._s(_vm.message))]):_vm._e(),(_vm.notice)?_c('span',{staticClass:"form__item-notice",attrs:{"role":"alert"}},[_vm._v(_vm._s(_vm.notice))]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }