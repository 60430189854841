<template>
  <div class="default-modal error-modal" :class="{ 'error-modal--center': center }">
    <div class="default-modal__header error-modal__header">
      <h3 class="default-modal__title">Ошибка</h3>
    </div>
    <div class="error-modal__body">
      <div class="error-modal__message">
        Конфликт настроек, обратитесь в службу поддержки
        <a :href="`tel:${$store.state.config.contact_center}`">
          {{ $store.state.config.contact_center | vMask("+# (###) ###-##-##") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/svg/CloseIcon.vue";

export default {
  name: "ErrorModal",
  props: {
    callback: Function,
    center: Boolean
  },
  methods: {
    confirm() {
      this.$emit("close");
      if (this.callback) {
        this.callback();
      }
    },
  },
  components: { CloseIcon },
};
</script>

<style lang="stylus">
.error-modal {
  width 100%
  max-width 400px

  &--center &__header {
    justify-content center
  }

  &__body {
    display flex
    flex-direction column
    width 100%
    padding: 15px 30px 30px 30px
  }

  &__message {
    width 100%
    font-size 1rem
    text-align center

    a {
      color var(--main_color)
      text-decoration underline
    }
  }

  & &__footer {
    flex-direction row
    justify-content center

    .btn {
      width 100%
      justify-content center
    }
  }
}
</style>
