<template>
  <div
    class="check__container"
    :class="{
      'check--error': errorMessage,
      'no-print': noPrint,
      'no-margin': noMargin,
    }"
    v-if="visible"
  >
    <div class="check__list no-print">
      <label
        :class="{
          'check--disabled': disabled,
          'check--checked': inputValue,
        }"
        class="check"
      >
        <span class="check__state" :class="{ 'check__state--circle': circle }">
          <CheckIcon v-if="circle" />
        </span>
        <input
          type="checkbox"
          class="check__input"
          v-model="inputValue"
          :disabled="disabled"
          @change="handleChange"
        />
        <slot>
          <span class="check__input-title">
            <span class="required__symbol" v-if="required">*</span> {{ title }}
          </span>
        </slot>
      </label>
    </div>
    <span class="form__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
    <span class="form__item-message" role="alert" v-if="message">{{ message }}</span>
    <span class="form__item-notice" role="alert" v-if="notice">{{ notice }}</span>
  </div>
</template>

<script>
import CheckIcon from "@/components/svg/CheckIcon.vue";

export default {
  name: "CheckboxComponent",
  components: {CheckIcon},
  props: {
    noPrint: Boolean,
    noMargin: Boolean,
    circle: Boolean,
    title: String,
    placeholder: {
      type: String,
      default: "",
    },
    options: Array,
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    value: [Number, String, Object, Boolean],
    errorMessage: String,
    message: String,
    notice: String,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value(e) {
      this.inputValue = e;
    },
  },
  methods: {
    handleChange() {
      this.$emit("input", this.inputValue);
    }
  }
};
</script>

<style lang="stylus">
.check {
  display flex
  position relative
  cursor pointer
  align-items: center;
  text-align: left;
  user-select none
  font-size: 1em;
  line-height: 16px;
  transition 0.2s
  margin-bottom 12px
  padding 3px
  --check-offset: 4px
  --check-width: 52px
  --check-height: 32px
  +below(420px) {
    --check-width: 40px
    --check-height: 26px
  }

  > *:not(:last-child) {
    margin-right 15px
  }

  &:hover {
    color var(--main_color)
    filter brightness(1.1)

    .check__state {
      border-color var(--main_color)
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom 24px
  }

  input {
    absolute left top
    visibility hidden
  }

  a {
    color var(--main_color)
  }

  .icon {
    display flex
  }

  &--error {
    color var(--color_red) !important

    .check__state {
      border-color var(--color_red) !important
    }
  }

  &--disabled {
    color var(--color_gray)

    .check__state {
      border: 1px solid var(--color_gray);
    }

    &.check--checked {
      .check__state {
        &:after {
          background: var(--color_dark);
        }
      }
    }
  }

  &--checked {
    &:not(&.check--disabled) {
      & .check__state {
        border none
        background var(--main_color)
        box-shadow: 0 4px 10px rgba(255, 145, 91, 0.5);

        &::before {
          left calc(var(--check-width) - var(--check-height) + var(--check-offset))
        }
      }
    }
  }

  &__container {
    &.no-margin {
      margin 0 !important
    }

    &:first-child {
      margin-top 5px
    }
  }

  &__state {
    position relative
    width var(--check-width)
    height var(--check-height)
    background: var(--color_gray);
    box-sizing: border-box;
    border-radius: 100px;
    display flex
    align-items center
    justify-content center
    flex-shrink 0
    transition 0.2s

    &::before {
      content ""
      display block
      position absolute
      top: var(--check-offset)
      left var(--check-offset)
      background-color var(--color_white)
      width calc(var(--check-height) - var(--check-offset) * 2)
      height calc(var(--check-height) - var(--check-offset) * 2)
      border-radius 50%
      transition 0.2s
    }

    &--circle {
      position relative
      width 22px
      height 22px
      background: var(--color_white);
      border: 1px solid var(--main_border_color);
      box-sizing: border-box;
      border-radius: 50%;
      display flex
      align-items center
      justify-content center
      flex-shrink 0
      margin-top 2px
      transition 0.2s

      &::before {
        display none
      }
    }
  }
}
</style>