<template>
  <form @submit.prevent="formSubmit" class="form" novalidate spellcheck="false">
    <template v-if="!codeSent">
      <div class="form__item">
        <span>Подтверди email (электронный адрес) и получи дополнительные бонусы</span>
      </div>
      <div class="form__item">
        <InputComponent
          v-model="form.email"
          title="E-mail"
          :disabled="loading || codeSent"
        />
      </div>
    </template>
    <template v-else>
      <div class="form__item">
        <InputComponent
          v-model="form.confirmCode"
          title="Код"
          :mask="vMaskStr"
          :disabled="loading || codeSent"
        />
      </div>
      <div class="form__message" v-if="emailMessage">{{ emailMessage }}</div>
      <div v-if="timer > 0" class="form__message">Повторная отправка кода будет доступна через: {{ timer }} секунд</div>
    </template>
    <div class="form__error" v-if="error">{{ error }}</div>
    <div
      v-if="!error && timer <= 0 && codeSent || $store.state.config.email === 1"
      class="form__actions form__actions--wrap"
    >
      <button
        v-if="!error && timer <= 0 && codeSent"
        @click.prevent="resendCode"
        class="btn-main btn-auto btn--sm"
        type="button"
        :disabled="timer > 0"
      >
        <template>Отправить код еще раз</template>
      </button>
      <button
        v-if="$store.state.config.email === 1 && !codeSent"
        type="button"
        @click.prevent="nextStep"
        class="btn-main"
      >
        Пропустить
      </button>
      <button
        v-else-if="$store.state.config.email_confirm === 1 && codeSent"
        type="button"
        @click.prevent="nextStep"
        class="btn-main btn--sm"
      >
        Подтвердить позже
      </button>
    </div>
    <div class="form__actions">
      <button type="button" @click.prevent="prevStep" class="btn-main">
        Назад
      </button>
      <button
        :type="codeSent ? 'button' : 'submit'"
        v-if="!codeSent"
        @click.prevent="sendCode"
        class="btn-main"
        :disabled="loading"
      >
        <LoadingIndicator v-if="loading"/>
        <template v-else>Далее</template>
      </button>
      <button
        v-else
        class="btn-main"
        :type="codeSent ? 'submit' : 'button'"
        :disabled="loading || form.confirmCode === null || codeLength !== form.confirmCode.length"
      >
        <LoadingIndicator v-if="loading"/>
        <template v-else>Далее</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";

export default {
  name: "EmailConfirm",
  props: {
    codes: Object,
    isBonusCard: Boolean,
  },
  data() {
    return {
      codeLength: 4,
      emailMessage: null,
      codeSent: false, // код подтверждения отправлен на почту
      loading: false,
      error: null,
      timer: this.$store.state.config.code_timer,
      interval: null,
      form: {
        confirmCode: null,
        email: null,
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch('GET_EMAIL', {
      token: localStorage.getItem("auth-token")
    })
      .then(({ data }) => {
        if (data.data && data.data.currentEmail && data.result.state === "Success") {
          this.form.email = data.data.currentEmail
          this.nextStep()
        }
      })
  },
  created() {
    if (!this.$store.state.config.email && !this.isBonusCard) {
      this.nextStep()
    }
  },
  computed: {
    vMaskStr() {
      return "#".repeat(this.codeLength)
    }
  },
  methods: {
    prevStep() {
      if (this.codeSent) {
        this.error = null
        this.codeSent = false
        this.form.confirmCode = null
      } else {
        this.$emit("changeStep", this.codes.QUESTIONS_CODE)
      }
    },
    nextStep() {
      if (this.isBonusCard) {
        this.$emit('changeStep', this.$store.state.codes.bonus_cards.FINISH_CODE)
      } else {
        this.$emit('changeStep', this.codes.QUESTIONS_CODE)
      }
    },
    countdown() {
      clearInterval(this.interval)
      this.timer = this.$store.state.config.code_timer
      this.interval = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(this.interval)
          return
        }
        this.timer -= 1
      }, 1000)
    },
    sendCode() {
      if (this.isEmailValid(this.form.email) && !this.loading) {
        this.loading = true
        this.error = null
        this.$store.dispatch("SET_EMAIL", {
          data: { email: this.form.email },
          token: localStorage.getItem("auth-token")
        })
            .then(({ data }) => {
              if (data && data.data && data.data.codeLength && data.result && data.result.state === "Success") {
                this.codeSent = true
                this.codeLength = data.data.codeLength
                this.error = null
                this.emailMessage = "Введите код подтверждения, отправленный на E-mail: " + this.form.email
                this.timer = this.$store.state.config.code_timer
                this.countdown()
              } else if (data.result && data.result.state === "Error") {
                this.error = data.result.message
                this.timer = 0
              }
            })
            .finally(() => {
              this.loading = false
            })
      } else {
        this.error = "Ошибка. Неверный ввод E-mail"
      }
    },
    resendCode() {
      this.$store.dispatch("SEND_EMAIL_CODE", {
        data: { },
        token: localStorage.getItem("auth-token")
      })
          .then(({ data }) => {
            if (data.result && data.result.state === "Success") {
              this.timer = this.$store.state.config.code_timer
              this.countdown()
            } else if (data.result && data.result.state === "Error") {
              this.error = data.result.message
            }
          })
    },
    formSubmit() {
      if (!this.loading) {
        this.loading = true

        this.$store.dispatch("EMAIL_CONFIRM", {
          data: {
            confirmCode: this.form.confirmCode,
            password: this.form.confirmCode,
          },
          token: localStorage.getItem("auth-token")
        })
            .then(({data}) => {
              if (data.result && data.result.state === "Success") {
                // если код подтвержден, убираем этот шаг
                this.$store.state.config.email = 0
                // проверка на необходимость шага ввода карты и переход на необходимый шаг
                this.nextStep()
              } else if (data.result.state === "Error") {
                this.error = data.result.message
              } else if (data.result.state === "ValidationError") {
                this.codeSent = true
                this.countdown()
                this.error = "Ошибка. Введите ранее отправленный код"
              }
              // this.$store.dispatch('REGISTRATION_ACTIONS', { token: localStorage.getItem("auth-token") }).then(() => {
              //   // проверка на наличие шага ввода карты и переход на необходимый шаг
              // })
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            });
      }
    },
    isEmailValid(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }
  },
  components: {
    InputComponent,
    LoadingIndicator,
  }
}
</script>
